//
import Request from '../utils/request.ts';
import Url from '../utils/url.ts';

//
class Callback {
	//
	static patch (code): any {
        //
        const data = {
            code: code,
            device: 'pc'
        };

		//
		const url = Url.stat;

		return Request.request({
			url: url,
			method: 'patch',
			data: data
		});
	}

	//
	static post (code): any {
        //
        const data = {
            code: code,
            device: 'pc'
        };

		//
		const url = Url.stat;

		// headers : {
        //     'Content-Type':'application/json;charset=utf-8'
        // },

		return Request.request({
			url: url,
			method: 'post',
			data: data,
			headers: {
				'Content-Type': 'application/json;charset=utf-8'
			}
		});
	}
}

//
export default Callback;