<template>
<section class="home">
	<!-- subject start -->
	<div class="flex align-items-center justify-content-around mx-auto h-100 w-100">
		<div class="subject">
			<div class="mb-2 host">蜜桃视频</div>
			<div class="mb-2">
				<img class="img-full" ref="img">
				<div ref="host" class="host1"></div>
			</div>			
			<div class="trade mb-2" @click="iosDownload">
				<div class="subject-svg">苹果下载</div>
				<svg t="1672497994268" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1662"><path d="M812.2 550.8c-7.2-96.1 56.4-156.8 116.4-199-56.4-50.6-105.4-99.5-209.1-104.5-83.6-3.4-121.8 43.8-200 45.5-72.7 1.7-67.3-52.3-218.2-37.1C170.5 269.2 55.9 390.6 65 560.9c9.1 183.8 147.3 438.3 276.3 438.3 90.9-1.7 112.7-42.1 187.3-42.1 92.7 0 114.5 50.6 187.3 40.5C841.4 980.7 959.5 797 959.5 738c-69.1-33.8-138.2-91.1-147.3-187.2z m-105.4-526C534.1 46.7 485 193.4 494.1 260.8c132.7 6.7 232.7-123.1 212.7-236z" fill="#ffffff" p-id="1663"></path></svg>
			</div>
			<div class="trade" @click="androidDownload">						
				<div class="subject-svg">安卓下载</div>
				<svg t="1672498264733" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2735"><path d="M229.493567 343.057721h565.073089v409.938453a67.449841 67.449841 0 0 1-67.02828 67.750957h-46.311543v139.717528a63.414895 63.414895 0 1 1-126.82979 0v-139.717528H469.602957v139.717528c0 35.049828-28.42529 63.354672-63.535341 63.354672a63.234226 63.234226 0 0 1-62.752442-63.354672l-0.542007-139.717528h-45.588866a67.570287 67.570287 0 0 1-67.690734-67.750957V343.057721z m-87.443901-11.683276a63.234226 63.234226 0 0 0-63.414895 62.752441v264.740626c0 35.110051 28.365067 63.354672 63.414895 63.354672s62.752441-28.304844 62.752442-63.354672V394.126886c0-34.387374-28.184398-62.752441-62.752442-62.752441z m654.323682-10.478815H227.024422c0-97.862493 58.536826-182.837248 145.318273-227.101206L328.560522 13.215953a8.973238 8.973238 0 0 1 3.071376-12.34573c4.275838-1.866915 9.876584-0.662454 12.34573 3.733831l44.263958 81.240925c37.63942-16.681791 79.494456-25.895921 123.758414-25.895922s86.118993 9.21413 123.758414 25.835698L680.022372 4.543831c2.469146-4.336061 8.069892-5.540523 12.34573-3.733831a8.973238 8.973238 0 0 1 3.071376 12.34573l-43.782173 80.578471c86.23944 44.324181 144.716043 129.298936 144.716043 227.161429zM406.067616 194.126063a23.968783 23.968783 0 0 0-23.90856-24.029006 23.90856 23.90856 0 0 0-23.426775 24.029006c0 12.887737 10.418592 23.968783 23.426775 23.968782a23.90856 23.90856 0 0 0 23.90856-23.968782z m259.200103 0a23.90856 23.90856 0 0 0-23.426775-24.029006 23.968783 23.968783 0 0 0 0 47.997788 23.788114 23.788114 0 0 0 23.426775-23.968782z m216.682615 137.248382a62.631995 62.631995 0 0 0-62.752442 62.752441v264.740626a63.053557 63.053557 0 1 0 126.167337 0V394.126886a62.872888 62.872888 0 0 0-63.414895-62.752441z" p-id="2736" fill="#ffffff"></path></svg>
			</div>
		</div>
	</div>
	<!-- subject end -->
</section>
</template>
<script>
//
import QRCode from 'qrcode';
//
import Callback from './unit/callback.ts';

//
export default {
	name: 'Home',
	data () {
		return {
			code: 0
		}
	},
	methods: {
		//
		initCode () {
			const query = location.search;
			if (query.indexOf("?") != -1) {
				const str = query.substr(1);
				const arr = str.split('=');
				const code = arr[1];
				this.code = code.substring(0, 4);
			}

			//
			this.transform();
			this.login();
		},
		//
		transform () {
			const that = this;
			const url = location.href;
			this.$refs.host.innerHTML = url;

			//
			const opts = {
				errorCorrectionLevel: 'H',
				type: 'image/jpeg',
				quality: 0.9,
				margin: 3
				// color: {
			    // 	dark:"#010599FF",
			    // 	light:"#FFBF60FF"
			  	// }
			};
			//
			QRCode.toDataURL(url, opts, function (err, url) {
				if (err) throw err
				that.$refs.img.src = url;
			});
		},
		//
		login () {
			Callback.post(this.code);
		},
		iosDownload () {
			const code = this.code;
			location.href = '/' + code + '.mobileconfig';
			Callback.patch('ios', code);						
		},
		androidDownload () {
			const code = this.code;
			location.href = '/' + code + '.apk';
			Callback.patch('android', code);					
		}
	},
	mounted () {
		this.initCode();
	}
}
</script>
