import axios from 'axios'
// import { ElMessage } from 'element-plus'
// import router from '@/router/index'
import Store from '@/utils/store.ts';
import config from '../config/index.ts';

// axios.defaults.withCredentials = true;
// axios.defaults.crossDomain = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
//'Content-Type': 'multipart/form-data'

//
// axios.interceptors.request.use(
//     (config) => {
//         const token = Store.get('token');
//         if (token && config.headers) {
//             // 请求头token信息，请根据实际情况进行修改
//             // config.headers['Authorization'] = token;
//             // config.headers['token'] = token;
//             config.headers['Authorization'] = 'Bearer ' + token;
//         }
//         return config;
//     },
//     //
//     (error) => {
//         Promise.reject(error);
//     },
// );

// use(then,catch);
axios.interceptors.response.use(res => {
    /*
    if (typeof res.data !== 'object') {
        Toast.fail('服务端异常！')
        return Promise.reject(res)
    }
    // if (res.data.resultCode != 200) {
    if (res.data.resultCode >= 400) {
        // console.log('###');
        // console.log(res);
        if (res.data.message) Toast.fail(res.data.message)
        if (res.data.resultCode == 416) {
            router.push({ path: '/login' })
        }
        //
        //if (res.data.data && window.location.hash == '#/login') {
        //    Store.set('token', res.data.data)
        //    axios.defaults.headers['token'] = res.data.data
        //}
      return Promise.reject(res.data)
    }
    return res.data
    */
    return res;
    // return Promise.reject(res.data)
    //
}, error => {
    //
    console.log('request error');
    //
    if (error.response) {
        console.log('response');
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        const message = error.response.data.message;
        // Dialog({ message: message });
        // Toast.fail(message);
    } else if (error.request) {
        console.log('request');
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // console.log('else');
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
    }
    //
    // Store.remove('token');
    // router.push({ 
    //    path: "/login",
    //    name: "login" 
    // });

    return Promise.reject(error);
});

// 请求拦截器，内部根据返回值，重新组装，统一管理。
/*
axios.interceptors.response.use(res => {
  // 
  if (typeof res.data !== 'object') {
    ElMessage.error('服务端异常！')
    return Promise.reject(res)
  }
  if (res.data.resultCode != 200) {
    if (res.data.message) ElMessage.error(res.data.message)
    if (res.data.resultCode == 419) {
      router.push({ path: '/login' })
    }
    return Promise.reject(res.data)
  }

  return res.data.data
})
*/

export default axios as Request;