//
const base = '/api/';
// const base = 'http://api.penguin2380.icu/api/';

//
const Url = {
  // user
  // login: base + 'login',
  // logout: base + 'logout',
  // register: base + 'register',
  base: base,
  //
  stat: base + 'hit'
}

export default Url;
